import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { BsArrowRight } from 'react-icons/bs'

export default function TestimonialItem ( props ) {

	const { testimonial } = props

	return (
		<div className="testimonial__list-item">
			<div className="testimonial__list-item-wrapper">
				<div className="testimonial__meta-container">
					<Img fluid={{ ...testimonial.thumbnail.imageFile.childImageSharp.fluid, aspectRatio: 1/1 }} className="testimonial__image" />
					<div className="testimonial__meta-info">
						{ testimonial.name }
						<span className="testimonial__meta-company">{ testimonial.company }</span>
					</div>
				</div>
				<div className="testimonial__content-container">
					<div className="testimonial__summary">{ testimonial.summary }</div>
					<Link to={ testimonial.slug } className="testimonial__link">
						Read more
						<BsArrowRight className="testimonial__link-icon" />
					</Link>
				</div>
			</div>
			
			
		</div>
	)
}