import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import TestimonialList from '../components/TestimonialsList/TestimonialsList'

export default function TestimonialsPage({ data: { strapi: { testimonials } }, location }) {

	return (
		<>
			<SEO title="Testimonials" pathname={ location.pathname } />

			<div className="content-page content-page--has-header">
				<div className="wrapper">
					<div className="content-page__header">
						<h1 className="content-page__heading">Testimonials</h1>
					</div>
				</div>

				<TestimonialList testimonials={ testimonials } />
			</div>
		</>
	)
}

export const query = graphql`
	query testimonialsPageQuery {
		strapi {
			testimonials {
				id
				title
				name
				company
				summary
				slug
				thumbnail {
					url
					imageFile {
						childImageSharp {
							fluid(maxWidth: 1000) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
			}
		}
	}
`