import React from 'react'

import AnimatedStroke from '../AnimatedStroke/AnimatedStroke'
import TestimonialItem from './TestimonialItem'

export default function TestimonialList ( props ) {

	const { testimonials } = props

	return (
		<section className="testimonial">

			<AnimatedStroke pathType={ 'profile-1' } cssClass="testimonial__stroke" pixelDelay={ 0 } reverse={ false } />

			<div className="wrapper">
				<div className="testimonial__list">
					{ testimonials.map( testimonial => (
						<TestimonialItem testimonial={ testimonial } key={ testimonial.id } />
					) ) }
				</div>
			</div>
		</section>
	)
}